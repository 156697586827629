import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _5bc268b0 = () => interopDefault(import('../src/pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _2ef7f270 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _05843071 = () => interopDefault(import('../src/pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _cb96f388 = () => interopDefault(import('../src/pages/initial-settings/company-profile.vue' /* webpackChunkName: "pages/initial-settings/company-profile" */))
const _f8715d7c = () => interopDefault(import('../src/pages/initial-settings/invite-members.vue' /* webpackChunkName: "pages/initial-settings/invite-members" */))
const _287c824a = () => interopDefault(import('../src/pages/initial-settings/preferences.vue' /* webpackChunkName: "pages/initial-settings/preferences" */))
const _bc4cb658 = () => interopDefault(import('../src/pages/initial-settings/user-profile.vue' /* webpackChunkName: "pages/initial-settings/user-profile" */))
const _642796fe = () => interopDefault(import('../src/pages/sessions/callback.vue' /* webpackChunkName: "pages/sessions/callback" */))
const _117948d5 = () => interopDefault(import('../src/pages/sessions/invitation.vue' /* webpackChunkName: "pages/sessions/invitation" */))
const _258845e4 = () => interopDefault(import('../src/pages/contents/_uuid/index.vue' /* webpackChunkName: "pages/contents/_uuid/index" */))
const _3113a2a0 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds" */))
const _74d57941 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds/new.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds/new" */))
const _1038dad0 = () => interopDefault(import('../src/pages/companies/_company_id/customers.vue' /* webpackChunkName: "pages/companies/_company_id/customers" */))
const _069ea929 = () => interopDefault(import('../src/pages/companies/_company_id/customers/new.vue' /* webpackChunkName: "pages/companies/_company_id/customers/new" */))
const _8bf046f4 = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/index" */))
const _0c239e06 = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/edit" */))
const _373d3cfc = () => interopDefault(import('../src/pages/companies/_company_id/expiration.vue' /* webpackChunkName: "pages/companies/_company_id/expiration" */))
const _029d6c1c = () => interopDefault(import('../src/pages/companies/_company_id/members.vue' /* webpackChunkName: "pages/companies/_company_id/members" */))
const _8cab36a8 = () => interopDefault(import('../src/pages/companies/_company_id/profile.vue' /* webpackChunkName: "pages/companies/_company_id/profile" */))
const _16a0bbca = () => interopDefault(import('../src/pages/companies/_company_id/projects/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/index" */))
const _1c08dec5 = () => interopDefault(import('../src/pages/companies/_company_id/reports/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/index" */))
const _b06b845a = () => interopDefault(import('../src/pages/companies/_company_id/setting.vue' /* webpackChunkName: "pages/companies/_company_id/setting" */))
const _7a8329fb = () => interopDefault(import('../src/pages/companies/_company_id/sharing-contents/index.vue' /* webpackChunkName: "pages/companies/_company_id/sharing-contents/index" */))
const _3bc0287e = () => interopDefault(import('../src/pages/companies/_company_id/unavailability.vue' /* webpackChunkName: "pages/companies/_company_id/unavailability" */))
const _53aa1ab4 = () => interopDefault(import('../src/pages/companies/_company_id/user-profile.vue' /* webpackChunkName: "pages/companies/_company_id/user-profile" */))
const _14d604d0 = () => interopDefault(import('../src/pages/companies/_company_id/projects/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/new" */))
const _2036b6b0 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/index" */))
const _3c41ae9c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id" */))
const _d87a3c96 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/index" */))
const _7af6d6a0 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/index" */))
const _7cec647e = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/detail.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/detail" */))
const _6ac08a47 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/member.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/member" */))
const _e069b53c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/reports.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/reports" */))
const _94c8a3f2 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/index" */))
const _cd805424 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/new" */))
const _5f2c2ae4 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/posts/index" */))
const _8195f154 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/fullscreen" */))
const _5f528abd = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/preview" */))
const _6e6b8765 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/print/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/print/index" */))
const _bdbff3fa = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/index" */))
const _ec57664c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/fullscreen" */))
const _fd6ec2f8 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/index" */))
const _cb909472 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/index" */))
const _5ce3a448 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/edit" */))
const _19d28d28 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index" */))
const _23e318a5 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/edit" */))
const _e5e8bb06 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/preview" */))
const _dbc9e1a0 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/index" */))
const _05476cb8 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/edit" */))
const _2b385d1e = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/preview" */))
const _4cea6e1c = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/edit" */))
const _716100d0 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/pdf" */))
const _1bec6e72 = () => interopDefault(import('../src/pages/companies/_company_id/project/_id/schedule/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/project/_id/schedule/pdf" */))
const _4e26015c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/companies",
      component: _5bc268b0,
      name: "companies"
    }, {
      path: "/logout",
      component: _2ef7f270,
      name: "logout"
    }, {
      path: "/sign_in",
      component: _05843071,
      name: "sign_in"
    }, {
      path: "/initial-settings/company-profile",
      component: _cb96f388,
      name: "initial-settings-company-profile"
    }, {
      path: "/initial-settings/invite-members",
      component: _f8715d7c,
      name: "initial-settings-invite-members"
    }, {
      path: "/initial-settings/preferences",
      component: _287c824a,
      name: "initial-settings-preferences"
    }, {
      path: "/initial-settings/user-profile",
      component: _bc4cb658,
      name: "initial-settings-user-profile"
    }, {
      path: "/sessions/callback",
      component: _642796fe,
      name: "sessions-callback"
    }, {
      path: "/sessions/invitation",
      component: _117948d5,
      name: "sessions-invitation"
    }, {
      path: "/contents/:uuid?",
      component: _258845e4,
      name: "contents-uuid"
    }, {
      path: "/companies/:company_id/construction-kinds",
      component: _3113a2a0,
      name: "companies-company_id-construction-kinds",
      children: [{
        path: "new",
        component: _74d57941,
        name: "companies-company_id-construction-kinds-new"
      }]
    }, {
      path: "/companies/:company_id/customers",
      component: _1038dad0,
      name: "companies-company_id-customers",
      children: [{
        path: "new",
        component: _069ea929,
        name: "companies-company_id-customers-new"
      }, {
        path: ":id",
        component: _8bf046f4,
        name: "companies-company_id-customers-id"
      }, {
        path: ":id/edit",
        component: _0c239e06,
        name: "companies-company_id-customers-id-edit"
      }]
    }, {
      path: "/companies/:company_id/expiration",
      component: _373d3cfc,
      name: "companies-company_id-expiration"
    }, {
      path: "/companies/:company_id/members",
      component: _029d6c1c,
      name: "companies-company_id-members"
    }, {
      path: "/companies/:company_id/profile",
      component: _8cab36a8,
      name: "companies-company_id-profile"
    }, {
      path: "/companies/:company_id/projects",
      component: _16a0bbca,
      name: "companies-company_id-projects"
    }, {
      path: "/companies/:company_id/reports",
      component: _1c08dec5,
      name: "companies-company_id-reports"
    }, {
      path: "/companies/:company_id/setting",
      component: _b06b845a,
      name: "companies-company_id-setting"
    }, {
      path: "/companies/:company_id/sharing-contents",
      component: _7a8329fb,
      name: "companies-company_id-sharing-contents"
    }, {
      path: "/companies/:company_id/unavailability",
      component: _3bc0287e,
      name: "companies-company_id-unavailability"
    }, {
      path: "/companies/:company_id/user-profile",
      component: _53aa1ab4,
      name: "companies-company_id-user-profile"
    }, {
      path: "/companies/:company_id/projects/new",
      component: _14d604d0,
      name: "companies-company_id-projects-new"
    }, {
      path: "/companies/:company_id/files/:id?",
      component: _2036b6b0,
      name: "companies-company_id-files-id"
    }, {
      path: "/companies/:company_id/projects/:id",
      component: _3c41ae9c,
      children: [{
        path: "",
        component: _d87a3c96,
        name: "companies-company_id-projects-id"
      }, {
        path: "board_posts",
        component: _7af6d6a0,
        name: "companies-company_id-projects-id-board_posts"
      }, {
        path: "detail",
        component: _7cec647e,
        name: "companies-company_id-projects-id-detail"
      }, {
        path: "member",
        component: _6ac08a47,
        name: "companies-company_id-projects-id-member"
      }, {
        path: "reports",
        component: _e069b53c,
        name: "companies-company_id-projects-id-reports"
      }, {
        path: "schedule",
        component: _94c8a3f2,
        name: "companies-company_id-projects-id-schedule"
      }, {
        path: "board_posts/new",
        component: _cd805424,
        name: "companies-company_id-projects-id-board_posts-new"
      }, {
        path: "board_posts/posts",
        component: _5f2c2ae4,
        name: "companies-company_id-projects-id-board_posts-posts"
      }, {
        path: "schedule/fullscreen",
        component: _8195f154,
        name: "companies-company_id-projects-id-schedule-fullscreen"
      }, {
        path: "schedule/preview",
        component: _5f528abd,
        name: "companies-company_id-projects-id-schedule-preview"
      }, {
        path: "schedule/print",
        component: _6e6b8765,
        name: "companies-company_id-projects-id-schedule-print"
      }, {
        path: "schedule/view",
        component: _bdbff3fa,
        name: "companies-company_id-projects-id-schedule-view"
      }, {
        path: "schedule/view/fullscreen",
        component: _ec57664c,
        name: "companies-company_id-projects-id-schedule-view-fullscreen"
      }, {
        path: "board_posts/:board_id",
        component: _fd6ec2f8,
        name: "companies-company_id-projects-id-board_posts-board_id"
      }, {
        path: "files/:file_id",
        component: _cb909472,
        name: "companies-company_id-projects-id-files-file_id"
      }, {
        path: "board_posts/:board_id/edit",
        component: _5ce3a448,
        name: "companies-company_id-projects-id-board_posts-board_id-edit"
      }, {
        path: "board_posts/:board_id/files",
        component: _19d28d28,
        name: "companies-company_id-projects-id-board_posts-board_id-files"
      }, {
        path: "files/:file_id/edit",
        component: _23e318a5,
        name: "companies-company_id-projects-id-files-file_id-edit"
      }, {
        path: "files/:file_id/preview",
        component: _e5e8bb06,
        name: "companies-company_id-projects-id-files-file_id-preview"
      }]
    }, {
      path: "/companies/:company_id/reports/:id",
      component: _dbc9e1a0,
      name: "companies-company_id-reports-id"
    }, {
      path: "/companies/:company_id/files/:id?/edit",
      component: _05476cb8,
      name: "companies-company_id-files-id-edit"
    }, {
      path: "/companies/:company_id/files/:id?/preview",
      component: _2b385d1e,
      name: "companies-company_id-files-id-preview"
    }, {
      path: "/companies/:company_id/reports/:id/edit",
      component: _4cea6e1c,
      name: "companies-company_id-reports-id-edit"
    }, {
      path: "/companies/:company_id/reports/:id/pdf",
      component: _716100d0,
      name: "companies-company_id-reports-id-pdf"
    }, {
      path: "/companies/:company_id/project/:id?/schedule/pdf",
      component: _1bec6e72,
      name: "companies-company_id-project-id-schedule-pdf"
    }, {
      path: "/",
      component: _4e26015c,
      name: "index"
    }],

    fallback: false
  })
}
